<template>
  <Loading v-if="isLoading" />
  <div v-else class="add-slider">
    <b-card>
      <div class="p-2 mb-1">
        <h1 class="m-0 page-title">{{ $t("sliders.slider_infromation") }}</h1>
      </div>
      <hr />
      <validation-observer ref="addSliderValidation">
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('sliders.title')" label-for="Title">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="Title"
                    type="text"
                    v-model="form_data.title"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('sliders.title')"
                    class="custom-input"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('sliders.title_btn')"
                label-for="Title Button"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title button"
                  rules="required"
                >
                  <b-form-input
                    id="Title Button"
                    name="Title Button"
                    type="text"
                    v-model="form_data.button_text"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('sliders.title_btn')"
                    class="custom-input"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('sliders.url')" label-for="Url">
                <validation-provider
                  #default="{ errors }"
                  name="Url"
                  rules="required"
                >
                  <b-form-input
                    id="Url"
                    type="text"
                    v-model="form_data.url"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('sliders.url')"
                  />
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div class="product-images-box">
                <b-form-group
                  :label="$t('sliders.web_image')"
                  label-for="Web Image"
                >
                  <validation-provider
                    :rules="isEdit ? '' : 'required'"
                    #default="{ errors }"
                    name="Web Image"
                  >
                    <div class="main-image">
                      <b-form-file
                        type="file"
                        accept="image/*"
                        class="main-image-input"
                        v-model="form_data.web"
                        @change="handleWebImage"
                        :state="errors.length > 0 ? false : null"
                      />
                      <img
                        v-if="webImage"
                        class="main-img"
                        :src="webImage"
                        alt="Add slider web image"
                      />
                      <img
                        v-else
                        :src="webImageIcon"
                        alt="Add slider web image"
                      />
                    </div>
                    <RecommendationUploadImage
                      v-if="
                        !form_data.web || (form_data.web.length == 0 && !isEdit)
                      "
                      :dimensions="{ width: 1200, height: 400 }"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="6">
              <div class="product-images-box">
                <b-form-group
                  :label="$t('sliders.mobile_image')"
                  label-for="Mobile Image"
                >
                  <validation-provider
                    :rules="isEdit ? '' : 'required'"
                    #default="{ errors }"
                    name="Mobile Image"
                  >
                    <div class="main-image">
                      <b-form-file
                        type="file"
                        accept="image/*"
                        class="main-image-input"
                        v-model="form_data.mobile"
                        @change="handleMobileImage"
                        :state="errors.length > 0 ? false : null"
                      />
                      <img
                        v-if="mobileImage"
                        class="main-img"
                        :src="mobileImage"
                        alt="Add slider mobile image"
                      />
                      <img
                        v-else
                        :src="mobileImageIcon"
                        alt="Add slider mobile image"
                      />
                    </div>
                    <RecommendationUploadImage
                      v-if="
                        !form_data.mobile ||
                        (form_data.mobile.length == 0 && !isEdit)
                      "
                      :dimensions="{ width: 600, height: 400 }"
                    />
                    <small class="text-danger">{{
                      errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </b-col>
            <b-col md="6">
              <b-form-checkbox
                switch
                v-model="form_data.is_active"
                :value="1"
                :unchecked-value="0"
              >
                {{ $t("sliders.enable_the_slide") }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
            block
            @click="addSliderForm"
            class="primary-btn my-3"
            :disabled="isPending"
          >
            <span>{{
              isEdit ? $t("sliders.edit_slide") : $t("sliders.add_slide")
            }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormSelect,
  BRow,
  BCol,
  BFormDatepicker,
  BFormCheckbox,
  BInputGroup,
  BCard,
  BFormFile,
  BFormTextarea,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

export default {
  name: "AddSlider",
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BRow,
    BCol,
    BFormDatepicker,
    BFormCheckbox,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
    Loading,
    BCard,
    BFormFile,
    BFormTextarea,
    RecommendationUploadImage,
  },
  data() {
    return {
      isEdit: false,
      isLoading: true,
      isPending: false,
      current_id: null,
      webImage: null,
      mobileImage: null,
      webImageIcon: require("@/assets/images/icons/web.svg"),
      mobileImageIcon: require("@/assets/images/icons/mobile.svg"),
      form_data: {
        is_active: 0,
        title: null,
        url: null,
        description: null,
        web: [],
        mobile: [],
        button_text: null,
        lang: localStorage.getItem("lang"),
      },
    };
  },

  created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.current_id = this.$route.params.id;
      this.getSliderData();
    }
    this.isLoading = false;
  },
  methods: {
    getSliderData() {
      this.$http
        .get(`admin/sliders/${this.current_id}`)
        .then((res) => {
          if (res.status === 200) {
            this.form_data = res.data.data;
            res.data.data.media.map((item) => {
              if (item.name === "web") {
                this.webImage = item.path;
              } else {
                this.mobileImage = item.path;
              }
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleWebImage() {
      const file = event.target.files[0];

      if (file) {
        this.webImage = URL.createObjectURL(file);
      }
    },
    handleMobileImage() {
      const file = event.target.files[0];

      if (file) {
        this.mobileImage = URL.createObjectURL(file);
      }
    },
    addSliderForm() {
      this.$refs.addSliderValidation.validate().then((success) => {
        if (success) {
          this.isPending = true;
          const formData = new FormData();
          for (const key in this.form_data) {
            formData.append(key, this.form_data[key]);
          }

          if (this.isEdit) {
            this.$http
              .post(
                `admin/sliders/slider-data/${this.current_id}?_method=put`,
                formData
              )
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  this.$helpers.makeToast(
                    "success",
                    res.data.message,
                    res.data.message
                  );
                  setTimeout(() => {
                    this.$router.push({ name: "slidersList" });
                  }, 1000);
                  this.isPending = false;
                }
              })
              .catch((err) => {
                this.$helpers.handleError(err);
                this.isPending = false;
              });
          } else {
            this.$http
              .post(`admin/sliders`, formData)
              .then((res) => {
                if (res.status === 200 || res.status === 201) {
                  this.$helpers.makeToast(
                    "success",
                    res.data.message,
                    res.data.message
                  );
                  setTimeout(() => {
                    this.$router.push({ name: "slidersList" });
                  }, 1000);
                  this.isPending = false;
                }
              })
              .catch((err) => {
                this.$helpers.handleError(err);
                this.isPending = false;
              });
          }
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
