var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loading'):_c('div',{staticClass:"add-slider"},[_c('b-card',[_c('div',{staticClass:"p-2 mb-1"},[_c('h1',{staticClass:"m-0 page-title"},[_vm._v(_vm._s(_vm.$t("sliders.slider_infromation")))])]),_c('hr'),_c('validation-observer',{ref:"addSliderValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('sliders.title'),"label-for":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"id":"Title","type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('sliders.title')},model:{value:(_vm.form_data.title),callback:function ($$v) {_vm.$set(_vm.form_data, "title", $$v)},expression:"form_data.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('sliders.title_btn'),"label-for":"Title Button"}},[_c('validation-provider',{attrs:{"name":"Title button","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"custom-input",attrs:{"id":"Title Button","name":"Title Button","type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('sliders.title_btn')},model:{value:(_vm.form_data.button_text),callback:function ($$v) {_vm.$set(_vm.form_data, "button_text", $$v)},expression:"form_data.button_text"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('sliders.url'),"label-for":"Url"}},[_c('validation-provider',{attrs:{"name":"Url","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Url","type":"text","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('sliders.url')},model:{value:(_vm.form_data.url),callback:function ($$v) {_vm.$set(_vm.form_data, "url", $$v)},expression:"form_data.url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"product-images-box"},[_c('b-form-group',{attrs:{"label":_vm.$t('sliders.web_image'),"label-for":"Web Image"}},[_c('validation-provider',{attrs:{"rules":_vm.isEdit ? '' : 'required',"name":"Web Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"main-image"},[_c('b-form-file',{staticClass:"main-image-input",attrs:{"type":"file","accept":"image/*","state":errors.length > 0 ? false : null},on:{"change":_vm.handleWebImage},model:{value:(_vm.form_data.web),callback:function ($$v) {_vm.$set(_vm.form_data, "web", $$v)},expression:"form_data.web"}}),(_vm.webImage)?_c('img',{staticClass:"main-img",attrs:{"src":_vm.webImage,"alt":"Add slider web image"}}):_c('img',{attrs:{"src":_vm.webImageIcon,"alt":"Add slider web image"}})],1),(
                      !_vm.form_data.web || (_vm.form_data.web.length == 0 && !_vm.isEdit)
                    )?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 400 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('div',{staticClass:"product-images-box"},[_c('b-form-group',{attrs:{"label":_vm.$t('sliders.mobile_image'),"label-for":"Mobile Image"}},[_c('validation-provider',{attrs:{"rules":_vm.isEdit ? '' : 'required',"name":"Mobile Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"main-image"},[_c('b-form-file',{staticClass:"main-image-input",attrs:{"type":"file","accept":"image/*","state":errors.length > 0 ? false : null},on:{"change":_vm.handleMobileImage},model:{value:(_vm.form_data.mobile),callback:function ($$v) {_vm.$set(_vm.form_data, "mobile", $$v)},expression:"form_data.mobile"}}),(_vm.mobileImage)?_c('img',{staticClass:"main-img",attrs:{"src":_vm.mobileImage,"alt":"Add slider mobile image"}}):_c('img',{attrs:{"src":_vm.mobileImageIcon,"alt":"Add slider mobile image"}})],1),(
                      !_vm.form_data.mobile ||
                      (_vm.form_data.mobile.length == 0 && !_vm.isEdit)
                    )?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 600, height: 400 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{attrs:{"switch":"","value":1,"unchecked-value":0},model:{value:(_vm.form_data.is_active),callback:function ($$v) {_vm.$set(_vm.form_data, "is_active", $$v)},expression:"form_data.is_active"}},[_vm._v(" "+_vm._s(_vm.$t("sliders.enable_the_slide"))+" ")])],1)],1),_c('b-button',{staticClass:"primary-btn my-3",attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.isPending},on:{"click":_vm.addSliderForm}},[_c('span',[_vm._v(_vm._s(_vm.isEdit ? _vm.$t("sliders.edit_slide") : _vm.$t("sliders.add_slide")))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }